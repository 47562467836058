<template>
<v-container fluid>
  <div class="d-flex align-center cyan lighten-5 pa-3 ma-n3">
    <v-icon color="teal" x-large>mdi-account-box</v-icon>
    <div class="text-h6 ml-2">管理员授权</div>
    <v-spacer></v-spacer>
    <v-btn @click="openAdminUserDlg()" class="mr-5" small color="primary">新增</v-btn>
  </div>
  <div class="px-3 pt-12">
    <v-data-table :headers="headers" :items="adminUsers" show-select :single-select="false" item-key="id" v-model="selected">
      <template v-slot:[`item.employeeName`]="{ item }">
        <span class="text-caption blue--text font-weight-light">{{ item.employeeName }}</span>
        <span v-if="item.user_role=='admin' || item.user_role=='creator'" class="text-caption blue--text font-weight-light">(老板)</span>
      </template>
      <template v-slot:[`item.user_role`]="{ item }">
        <div v-if="item.user_role=='creator'">助理</div>
        <div v-if="item.user_role=='admin'">超级管理员</div>
        <div v-if="item.user_role=='editor'">后台管理员</div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon @click="removeAdmin(item.id)" class="mx-2" small color="black">mdi-undo-variant</v-icon>
        <v-icon @click="openAdminUserDlg(item.id)" class="mx-2" small color="black">mdi-pencil-outline</v-icon>
      </template>
    </v-data-table>
  </div>
  <remove-confirm-dlg ref="removeConfirmDlg"></remove-confirm-dlg>
  <admin-user-dlg @agree="updateAdminUser" ref="adminUserDlg"></admin-user-dlg>
</v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  name: 'AdminManagePage',
  components: {
    RemoveConfirmDlg: () => import('@/components/admin/management/RemoveConfirmDlg.vue'),
    AdminUserDlg: () => import('@/components/admin/management/AdminUserDlg.vue')
  },
  data: () => ({
    headers: [{
        text: '姓名',
        align: 'start',
        sortable: false,
        value: 'employeeName',
        width: '300px'
      },
      {
        text: '身份',
        value: 'user_role',
      },
      {
        text: '操作',
        value: 'action',
        width: '200px',
      }
    ],
    selected: [],
    newAdmin: {
      id: null,
      user_role: 'user',
      user_permission: ''
    }
  }),
  computed: {
    ...mapGetters('user', ['users']),
    adminUsers: function () {
      return this.users.filter(user => user.user_role !== 'user');
    }
  },
  methods: {
    ...mapActions('user', ['getUsers', 'updateUser']),
    async removeAdmin(id) {
      if(await this.$refs.removeConfirmDlg.open()) {
        this.updateUser({
          id: id,
          user_role: 'user'
        });
      }
    },
    openAdminUserDlg(id=null) {
      if(!id) {
        this.$refs.adminUserDlg.open(Object.assign({}, this.newAdmin))
      } else {
        const index = this.users.findIndex(user => user.id == id);
        const user = {
          id: id,
          user_role: this.users[index].user_role,
          user_permission: this.users[index].user_permission
        };
        this.$refs.adminUserDlg.open(user);
      }
    },
    updateAdminUser(e) {
      e.user_permission = e.user_permission.join();
      this.updateUser(e);
    }
  },
  mounted() {
    this.getUsers();
  }
}
</script>
